import { Fragment, useEffect, useState } from "react";
import * as React from "react";
import { useTheme } from "@emotion/react";
import {
  IconBankFilled,
  IconBrandPayPal,
  IconCardOutlined,
  IconCheck,
  IconInfoOutlined,
  IconStaticBrandPlaid,
} from "@hubble/icons";
import * as Sentry from "@sentry/browser";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track, trackBrazeEvent } from "@gemini-ui/analytics";
import { PAGE_NAME } from "@gemini-ui/analytics/constants/events";
import { useFeatureFlags } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import { VerificationPendingActionType } from "@gemini-ui/components/Header/VerificationPendingModal/utils";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import LockoutMessage from "@gemini-ui/components/Lockout/LockoutMessage";
import { lockoutTransactionAllowed } from "@gemini-ui/components/Lockout/utils";
import { PayPalModal } from "@gemini-ui/components/PayPalModal";
import { PlaidModal } from "@gemini-ui/components/PlaidModal";
import { isPlaidRedirect } from "@gemini-ui/components/PlaidModal/utils";
import { LoadingErrorSection } from "@gemini-ui/components/Services/LoadingErrorSection";
import { ChangeCurrencyMenu } from "@gemini-ui/components/Transfer/CashDepositFlow/ChangeCurrencyMenu";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { InitialWireFormType } from "@gemini-ui/constants/giact";
import { QueryParams } from "@gemini-ui/constants/queryParams";
import { GeminiEntities } from "@gemini-ui/constants/templateProps/account";
import { usePageData, usePageRefresh } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal, VerificationPendingProps } from "@gemini-ui/contexts/GlobalModal";
import {
  Avatar,
  Button,
  Card,
  Flex,
  IconBadge,
  List,
  Modal,
  SectionMessage,
  SkeletonLoader,
  Spacer,
  Spacing,
  Text,
  Tooltip,
  useToaster,
} from "@gemini-ui/design-system";
import useAjaxModal from "@gemini-ui/hooks/useAjaxModal";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks/useGrowFeatureFlags";
import AddDebitCardFlowWithIntl from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow";
import { AddPaymentMethodOption } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodOption";
import {
  AddPaymentMethodModalState,
  AddPaymentMethodOptionsProps,
  AddPaymentModalProps,
  getIntlDescriptionCopy,
  getIntlTitleCopy,
  LinkPaymentType,
  PaymentTypeScope,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import renderPaymentMethodByType from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/renderPaymentMethodByType";
import { PaymentMethodDescriptionContainer } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/styles";
import TransferLimitAndTimingModal from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/transferLimitAndTiming/TransferLimitAndTimingModal";
import {
  areNewAddPaymentMethodsEnabled,
  getManualDescription,
  getPlaidDescription,
  IneligiblePaymentMessageMap,
  listAvailablePaymentTypes,
  PaymentTypeBifurcationMap,
  shouldShowCurrencyDropdown,
  trackLinkFundingStarted,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/utils";
import SelectCurrencyModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/SelectCurrencyModal";
import DisabledButtonWithTooltip from "@gemini-ui/pages/settings/BankSettings/components/DisabledButtonWithTooltip";
import { GiactModal } from "@gemini-ui/pages/settings/BankSettings/components/GiactModal";
import { FlexContainer } from "@gemini-ui/pages/settings/BankSettings/styles";
import { AjaxWireModal } from "@gemini-ui/pages/settings/BankSettings/WireVerify";
import { useAchDisabledFlow } from "@gemini-ui/pages/transfers/utils/useAchDisabledFlow";
import { useSentryTeam } from "@gemini-ui/sentry/hooks";
import { SENTRY_TEAMS } from "@gemini-ui/sentry/teams";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getIsUserInOnboarding, useLockout } from "@gemini-ui/services/user/lockout";
import { BankSettingsPageDataType } from "@gemini-ui/transformers/PaymentMethods";
import { useIntl } from "@gemini-ui/utils/intl";

const AddWireCta = ({
  onClick,
  disabled = false,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const {
    pageProps: { canManagePaymentMethods },
  } = usePageData<BankSettingsPageDataType>();
  const { intl } = useIntl();
  if (!canManagePaymentMethods) {
    return (
      <DisabledButtonWithTooltip
        display="inline"
        size="sm"
        tooltipMessage={<LockoutMessage lockout={LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS} />}
        data-testid="disabled-link-bank-btn"
        disabled={disabled}
      >
        {intl.formatMessage({ defaultMessage: "Add" })}
      </DisabledButtonWithTooltip>
    );
  }
  return (
    <Button.Primary size="sm" data-testid="link-bank-via-wire" onClick={onClick} display="inline" disabled={disabled}>
      {intl.formatMessage({ defaultMessage: "Add" })}
    </Button.Primary>
  );
};

const PaymentMethodDescription = ({
  details,
  renderCustomDescription,
}: {
  details: string[];
  renderCustomDescription?: React.ReactNode;
}) => {
  return (
    <PaymentMethodDescriptionContainer>
      {details.map((detail, index) => (
        <FlexContainer key={typeof detail === "string" ? detail : index}>
          <IconBadge size="sm" icon={<IconCheck />} />
          <Text.Body ml={1} size="sm">
            {detail}
          </Text.Body>
        </FlexContainer>
      ))}
      {renderCustomDescription}
    </PaymentMethodDescriptionContainer>
  );
};

export const AddPaymentMethodOptions = ({
  handleOpenWireModal,
  handleOpenBancolombiaModal,
}: AddPaymentMethodOptionsProps) => {
  const {
    templateProps: {
      account,
      account: { defaultFiat, geminiEntity },
      user: { countryCode, fullName, subaccountHashid },
    },
    pageProps: { canManagePaymentMethods, isPlaidRuxEnabled, isPlaidSupported, paymentMethods, userDetails },
  } = usePageData<BankSettingsPageDataType>();
  const { lockout, isLoadingLockout, lockoutError, fetchLockout } = useLockout();

  const { requestRefresh } = usePageRefresh();
  const { showToast } = useToaster();
  const GiactManualAddBankAccount = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.GIACT_MANUAL_ADD_BANK_ACCOUNT
  );
  const shouldHideCardsForMoonbaseLaunch = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_HIDE_PAYMENT_METHODS_FOR_MOONBASE_LAUNCH
  );
  const PayPalEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.PAYPAL_ENABLED);
  const { BcoloEnabled } = useFeatureFlags();
  let { AddDebitCards } = useFeatureFlags();
  AddDebitCards = shouldHideCardsForMoonbaseLaunch ? false : AddDebitCards;
  const [showPlaidModal, setShowPlaidModal] = React.useState(isPlaidRedirect());
  const [isPayPalModalOpen, setIsPayPalModalOpen] = React.useState(false);
  const [isGiactModalOpen, setIsGiactModalOpen] = React.useState(false);
  const [isDebitCardModalOpen, setIsDebitCardModalOpen] = React.useState(false);
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const { intl } = useIntl();
  const titles = getIntlTitleCopy(intl);
  const theme = useTheme();

  const addPaymentTypeList = listAvailablePaymentTypes(
    account,
    countryCode,
    {
      AddDebitCards,
      BcoloEnabled,
      PayPalEnabled,
      GiactManualAddBankAccount,
      WireTransferEnabled: true,
    },
    isPlaidSupported,
    defaultFiat
  );
  const { DEPOSIT_AND_WITHDRAW, VERIFY_CARD_WITH_PHOTO, LOGIN_WITH_BCOLO, DEPOSIT, WITHDRAW } =
    getIntlDescriptionCopy(intl);

  const handlePlaidClick = () => {
    const { name, properties } = EVENTS.OPEN_PLAID;
    trackBrazeEvent(name);
    track(name, { [properties.TRIGGER]: PAGE_NAME.BANK_SETTINGS });
    setShowPlaidModal(true);
  };

  const handleOpenPayPalModal = () => {
    const { name, properties } = EVENTS.OPEN_PAYPAL;
    track(name, { [properties.TRIGGER]: PAGE_NAME.BANK_SETTINGS });
    setIsPayPalModalOpen(true);
  };

  const handleOpenGiactModal = () => {
    const { name, properties } = EVENTS.OPEN_GIACT;
    track(name, { [properties.TRIGGER]: PAGE_NAME.BANK_SETTINGS });
    setIsGiactModalOpen(true);
  };
  const handleOpenDebitCardModal = () => {
    const { name, properties } = EVENTS.GET_STARTED_DEBIT_CARD;
    track(name, { [properties.TRIGGER]: PAGE_NAME.BANK_SETTINGS });
    setIsDebitCardModalOpen(true);
  };

  const handlePlaidUnsupportedModalSubmit = () => {
    setShowPlaidModal(false);
    if (GiactManualAddBankAccount && defaultFiat === "USD" && countryCode === "us") {
      setIsGiactModalOpen(true);
    } else {
      handleOpenWireModal();
    }
  };

  const handleClosePlaidModal = () => {
    const redirectUrl = new URLSearchParams(window.location.search).get(QueryParams.REDIRECT_URL);
    const domainRegex = /http(?:s?):\/\/.*?([^\.\/]+?\.[^\.]+?)(?:\/|$)/gu;
    const includesDomain = domainRegex.test(redirectUrl);

    if (redirectUrl && !includesDomain) {
      return window.location.replace(decodeURIComponent(redirectUrl));
    }
    setShowPlaidModal(false);
  };
  const achDisabledFlow = useAchDisabledFlow();

  if (isLoadingLockout) {
    return (
      <Card mb={8} data-testid="add-payment-method-options-skeleton-loader">
        <Flex width="100%">
          <Flex width="100%" alignItems="center" gap={Spacing.scale["1"]}>
            <Avatar name="" />
            <SkeletonLoader width="60px" />
          </Flex>
          <Flex width="100%" alignItems="center">
            <Avatar name="" />
            <Flex ml={1} width="100%" flexDirection="column" gap={Spacing.scale["0.5"]}>
              <SkeletonLoader />
              <SkeletonLoader />
            </Flex>
          </Flex>
          <Flex width="100%" alignItems="center" justify="end">
            <SkeletonLoader width="60px" />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (lockoutError) {
    return (
      <LoadingErrorSection
        refetchData={() => {
          fetchLockout(true);
        }}
        errorMessage={intl.formatMessage({
          defaultMessage:
            "An error occurred while initialing the Add Payment Method Options Module. Please try again and or contact customer support.",
        })}
        data-testid="add-payment-method-options-error"
      />
    );
  }

  return (
    <React.Fragment>
      <Text.Heading size="md" mb={3} data-testid="add-payment-method-options-heading">
        {intl.formatMessage({ defaultMessage: "Add payment method" })}
      </Text.Heading>
      {achDisabledFlow && (
        <SectionMessage statusType="info" mb={2} data-testid="ach-unavail-message">
          {intl.formatMessage({
            defaultMessage:
              "Bank account linking is temporarily unavailable. We are waiving fees associated with alternate funding methods during this time.",
          })}
        </SectionMessage>
      )}
      <Spacer mb={6}>
        {addPaymentTypeList.map((paymentType, index) => {
          const isRecommended = index === 0 && addPaymentTypeList.length !== 1;
          switch (paymentType) {
            case LinkPaymentType.PLAID:
              return (
                !achDisabledFlow && (
                  <AddPaymentMethodOption
                    isRecommended={isRecommended}
                    key={paymentType}
                    icon={<IconBadge icon={<IconStaticBrandPlaid />} />}
                    title={titles.LINK_BANK_VIA_PLAID_TITLE}
                    description={
                      <PaymentMethodDescription
                        details={
                          isNewAddPaymentsFlowEnabled
                            ? [DEPOSIT, WITHDRAW]
                            : getPlaidDescription(countryCode, geminiEntity, intl)
                        }
                      />
                    }
                    cta={
                      <Tooltip
                        disabled={canManagePaymentMethods}
                        data-testid="disabled-link-plaid-tooltip"
                        overlay={<LockoutMessage lockout={LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS} />}
                      >
                        <Button.Primary
                          display="inline"
                          disabled={!canManagePaymentMethods}
                          size="sm"
                          data-testid="link-bank-plaid"
                          onClick={handlePlaidClick}
                        >
                          {intl.formatMessage({ defaultMessage: "Add" })}
                        </Button.Primary>
                      </Tooltip>
                    }
                  />
                )
              );

            case LinkPaymentType.GIACT:
              return (
                <AddPaymentMethodOption
                  key={paymentType}
                  title={titles.LINK_GIACT_TITLE}
                  icon={<IconBadge icon={<IconBankFilled />} />}
                  isRecommended={isRecommended}
                  description={<PaymentMethodDescription details={[DEPOSIT_AND_WITHDRAW]} />}
                  cta={
                    <Tooltip
                      disabled={canManagePaymentMethods}
                      data-testid="disabled-link-add-giact"
                      overlay={<LockoutMessage lockout={LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS} />}
                    >
                      <Button.Primary
                        display="inline"
                        disabled={!canManagePaymentMethods}
                        size="sm"
                        data-testid="link-bank-giact"
                        onClick={handleOpenGiactModal}
                      >
                        {intl.formatMessage({ defaultMessage: "Add" })}
                      </Button.Primary>
                    </Tooltip>
                  }
                />
              );
            case LinkPaymentType.MANUAL:
              return (
                !achDisabledFlow && (
                  <AddPaymentMethodOption
                    isRecommended={isRecommended}
                    key={paymentType}
                    icon={<IconBadge icon={<IconBankFilled />} />}
                    title={titles.LINK_BANK_MANUALLY_TITLE}
                    description={
                      <PaymentMethodDescription
                        details={
                          isNewAddPaymentsFlowEnabled
                            ? [DEPOSIT, WITHDRAW]
                            : getManualDescription(account.geminiEntity, intl)
                        }
                      />
                    }
                    cta={<AddWireCta onClick={() => handleOpenWireModal(null)} />}
                  />
                )
              );
            case LinkPaymentType.DEBIT:
              return (
                <AddPaymentMethodOption
                  key={paymentType}
                  title={titles.LINK_DEBIT_CARD_TITLE}
                  icon={<IconBadge icon={<IconCardOutlined />} />}
                  description={<PaymentMethodDescription details={[VERIFY_CARD_WITH_PHOTO]} />}
                  cta={
                    <Tooltip
                      disabled={canManagePaymentMethods}
                      data-testid="disabled-link-add-debit-tooltip"
                      overlay={<LockoutMessage lockout={LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS} />}
                    >
                      <Button.Primary
                        display="inline"
                        disabled={!canManagePaymentMethods}
                        size="sm"
                        data-testid="link-debit-card"
                        onClick={handleOpenDebitCardModal}
                      >
                        {intl.formatMessage({ defaultMessage: "Add" })}
                      </Button.Primary>
                    </Tooltip>
                  }
                />
              );

            case LinkPaymentType.PAYPAL:
              return (
                <AddPaymentMethodOption
                  key={paymentType}
                  title={titles.LINK_PAYPAL_TITLE}
                  icon={<IconBadge icon={<IconBrandPayPal color={theme.isDark ? "#ffffff" : "#253B80"} />} />}
                  description={<PaymentMethodDescription details={[DEPOSIT]} />}
                  cta={
                    <Tooltip
                      disabled={canManagePaymentMethods}
                      data-testid="disabled-link-add-paypal"
                      overlay={<LockoutMessage lockout={LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS} />}
                    >
                      <Button.Primary
                        display="inline"
                        disabled={!canManagePaymentMethods}
                        size="sm"
                        data-testid="link-paypal"
                        onClick={handleOpenPayPalModal}
                      >
                        {intl.formatMessage({ defaultMessage: "Add" })}
                      </Button.Primary>
                    </Tooltip>
                  }
                />
              );

            case LinkPaymentType.BANCOLOMBIA:
              const canLinkBancolombia = lockoutTransactionAllowed({ lockout, authorized: canManagePaymentMethods });
              return (
                <AddPaymentMethodOption
                  key={paymentType}
                  title={titles.LINK_BANCOLOMBIA_TITLE}
                  isRecommended={isRecommended}
                  icon={<IconBadge icon={<IconBankFilled />} />}
                  description={<PaymentMethodDescription details={[DEPOSIT_AND_WITHDRAW, LOGIN_WITH_BCOLO]} />}
                  cta={
                    <Tooltip
                      disabled={canLinkBancolombia}
                      data-testid="disabled-link-bancolombia-tooltip"
                      overlay={<LockoutMessage lockout={lockout || LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS} />}
                    >
                      <Button.Primary
                        display="inline"
                        disabled={!canLinkBancolombia}
                        size="sm"
                        data-testid="link-bancolombia"
                        onClick={handleOpenBancolombiaModal}
                      >
                        {intl.formatMessage({ defaultMessage: "Add" })}
                      </Button.Primary>
                    </Tooltip>
                  }
                />
              );
          }
        })}
      </Spacer>
      <PlaidModal
        isPlaidRuxEnabled={isPlaidRuxEnabled}
        userCountryCode={countryCode}
        isOpen={showPlaidModal}
        handlePlaidSuccess={requestRefresh}
        onClose={handleClosePlaidModal}
        handlePlaidUnsupportedModalSubmit={handlePlaidUnsupportedModalSubmit}
        openGiactModal={() => {
          setIsGiactModalOpen(true);
          setShowPlaidModal(false);
        }}
        isSettingsOrOnboarding={true}
        subaccountHashid={subaccountHashid}
      />
      <PayPalModal
        handlePayPalSuccess={async () => {
          await requestRefresh();
          setIsPayPalModalOpen(false);
          showToast({
            message: intl.formatMessage({ defaultMessage: "Payment method added." }),
          });
        }}
        isOpen={isPayPalModalOpen}
        handleClosePayPal={() => setIsPayPalModalOpen(false)}
        paymentMethods={paymentMethods}
        isSettingsOrOnboarding={true}
        subaccountHashid={subaccountHashid}
      />
      {isGiactModalOpen && (
        <GiactModal
          isOpen={isGiactModalOpen}
          handleGiactSuccess={() => {
            setIsGiactModalOpen(false);
            requestRefresh();
          }}
          handleFallbackToWire={handleOpenWireModal}
          handleGiactClose={() => setIsGiactModalOpen(false)}
          isSettingsOrOnboarding={true}
          subaccountHashid={subaccountHashid}
        />
      )}
      {isDebitCardModalOpen && (
        <AddDebitCardFlowWithIntl
          isOpen={isDebitCardModalOpen}
          onClose={() => setIsDebitCardModalOpen(false)}
          defaultFiat={defaultFiat}
          requestRefresh={requestRefresh}
          settingsFlow={true}
          defaultCardHolderName={fullName}
          userDetails={userDetails}
          subaccountHashid={subaccountHashid}
        />
      )}
    </React.Fragment>
  );
};

const controller = jsRoutes.com.gemini.web.server.funding.controllers.WireSettingsController.addBankAccountGet(false);

type BankSettingsPageProps = {
  canManageBankAccounts: boolean;
  isPlaidRuxEnabled: boolean;
  isPlaidSupported: boolean;
  userDetails: BankSettingsPageDataType["userDetails"];
};

export const getOrderedAvailablePaymentTypes = (
  isNewAddPaymentsFlowEnabled: boolean,
  availablePaymentTypes: LinkPaymentType[]
) => {
  if (
    isNewAddPaymentsFlowEnabled &&
    ![LinkPaymentType.PLAID, LinkPaymentType.PLAID_OR_GIACT].includes(availablePaymentTypes[0]) &&
    availablePaymentTypes[availablePaymentTypes.length - 1] === LinkPaymentType.WIRE
  ) {
    const recommendedWireMethod = availablePaymentTypes[availablePaymentTypes.length - 1];
    return [recommendedWireMethod, ...availablePaymentTypes.slice(0, availablePaymentTypes.length - 1)];
  }
  return availablePaymentTypes;
};

export const AddPaymentMethodsModal = ({
  scope,
  type = "default",
  handleOpenBancolombiaModal,
  isOpen,
  onClose,
  onSuccess,
  onBack,
  initialModalState = AddPaymentMethodModalState.SELECT_PAYMENT_METHOD,
  pageName = PAGE_NAME.BANK_SETTINGS,
  paymentMethods,
  refreshPaymentMethodsData,
  isRedirectedFromWithdrawals = false,
  ineligibleCardSubTitle,
  updateSelectedCurrency,
  transferFlowCurrency,
  addDebitCardFlowProps,
  onCurrencyChange,
  openSelectPaymentMethodModal,
  subaccountHashid,
  noPaymentMethodInDeposit = false,
}: AddPaymentModalProps) => {
  useSentryTeam(SENTRY_TEAMS.Payments);
  const {
    templateProps,
    templateProps: {
      account,
      account: { defaultFiat, geminiEntity, supportedFiat },
      user: { countryCode, advancedTradeUIEnabled, fullName, isInstitutional },
    },
  } = usePageData();
  const [selectedCurrency, setSelectedCurrency] = useState(transferFlowCurrency ? transferFlowCurrency : defaultFiat);

  const newPaymentMethodsEnabledForGlobal = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP_GLOBAL
  );
  const NewPaymentMethodsEnabled = newPaymentMethodsEnabledForGlobal
    ? newPaymentMethodsEnabledForGlobal
    : areNewAddPaymentMethodsEnabled(selectedCurrency);

  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const { showToast } = useToaster();
  const {
    pageName: ajaxModalPageName,
    toggleModal: toggleWireModal,
    pageProps,
    modalState: ajaxModalState,
    setAjaxData,
    isOpen: isWireModalOpen,
  } = useAjaxModal({
    controller,
    isInitialOpen: false,
  });
  const redirectUrl = new URLSearchParams(window.location.search).get(QueryParams.REDIRECT_URL);
  const [modalState, setModalState] = React.useState<AddPaymentMethodModalState>(initialModalState);
  const [giactFormValues, setGiactFormValues] = useState<InitialWireFormType>(null);
  const isSettingsOrOnboarding = type === "settings" || type === "onboarding";
  const { requestRefresh } = usePageRefresh();

  const [bankSettingsProps, setBankSettingsProps] = React.useState<BankSettingsPageProps>(null);

  const GiactManualAddBankAccount = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.GIACT_MANUAL_ADD_BANK_ACCOUNT
  );

  const PayPalEnabled =
    optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.PAYPAL_ENABLED) &&
    countryCode === "us" &&
    selectedCurrency === "USD";

  const shouldHideCardsForMoonbaseLaunch = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_HIDE_PAYMENT_METHODS_FOR_MOONBASE_LAUNCH
  );

  const { BcoloEnabled } = useFeatureFlags();
  let { AddDebitCards } = useFeatureFlags();
  AddDebitCards = shouldHideCardsForMoonbaseLaunch ? false : AddDebitCards;
  const [issuingCountryCode, setIssuingCountryCode] = useState("");
  const isForeignEntityACHDisabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_FOREIGN_ENTITY_ACH_DISABLED
  );

  useEffect(() => {
    if (isOpen) {
      trackLinkFundingStarted();
    }
  }, [isOpen]);

  // This controller query anti pattern will be replaced soon™️. This is a temporary fix to get the bank settings props.
  useEffect(() => {
    if (isOpen) {
      axios
        // add header for subaccount
        .get<{ pageProps: BankSettingsPageProps }>(
          jsRoutes.com.gemini.web.server.funding.controllers.BankSettingsController.get().url,
          {
            headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
          }
        )
        .then(({ data }) => {
          const { canManageBankAccounts, isPlaidRuxEnabled, isPlaidSupported, userDetails } = data.pageProps;

          setBankSettingsProps({ canManageBankAccounts, isPlaidRuxEnabled, isPlaidSupported, userDetails });
        })
        .catch(e => {
          Sentry.captureException(e);
          showToast({
            message: intl.formatMessage({
              defaultMessage: "Unable to add a payment method at this time, please try again later.",
            }),
            onDismiss: () => onClose(),
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, defaultFiat, subaccountHashid]);

  useEffect(() => {
    const fetchIssuingCountryCode = () => {
      axios
        .get(
          jsRoutes.com.gemini.web.server.account.controllers.AccountSelectionController.getForeignEntityIssuingCountry()
            .url
        )
        .then(response => setIssuingCountryCode(response.data))
        .catch(error => Sentry.captureException(error));
    };
    if (isInstitutional && isForeignEntityACHDisabled) {
      fetchIssuingCountryCode();
    }
  }, [isForeignEntityACHDisabled, isInstitutional]);

  const { intl } = useIntl();
  const titles = getIntlTitleCopy(intl);
  const theme = useTheme();
  const achDisabledFlow = useAchDisabledFlow();
  const { eligibleForStaking, eligibleForPrivateStaking } = useGrowFeatureFlags();
  const showStaking = eligibleForStaking || eligibleForPrivateStaking;

  const handlePayPalSuccess = React.useCallback(async () => {
    if (onSuccess) return onSuccess(LinkPaymentType.PAYPAL);
    await refreshPaymentMethodsData();
    onClose();
    setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
    showToast({
      message: intl.formatMessage({ defaultMessage: "Payment method added." }),
    });
  }, [intl, onClose, refreshPaymentMethodsData, showToast, onSuccess]);

  const handleAddDebitClick = () => {
    const { name, properties } = EVENTS.GET_STARTED_DEBIT_CARD;
    track(name, { [properties.TRIGGER]: ajaxModalPageName });
    track(EVENTS.ADD_DEBIT_CARD_ADDRESS.name, {
      [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.MODE]: "Default",
      [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.STATUS]: "Open",
      [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.IS_DEFAULT_ADDRESS_CHECKED]: "Checked",
    });
  };

  const handleGiactSuccess = React.useCallback(async () => {
    if (onSuccess) return onSuccess(LinkPaymentType.GIACT);

    await refreshPaymentMethodsData();
    onClose();
    // unmounts giact modal
    setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
  }, [onClose, refreshPaymentMethodsData, onSuccess]);
  // Ireland users
  const handleClick = (paymentType: LinkPaymentType) => {
    switch (paymentType) {
      case LinkPaymentType.DEBIT: {
        setModalState(AddPaymentMethodModalState.ADD_DEBIT_CARD);
        handleAddDebitClick();
        break;
      }
      case LinkPaymentType.PLAID: {
        const { name, properties } = EVENTS.OPEN_PLAID;
        track(name, { [properties.TRIGGER]: pageName });
        setModalState(AddPaymentMethodModalState.ADD_BANK_PLAID);
        break;
      }
      case LinkPaymentType.PAYPAL: {
        const { name, properties } = EVENTS.OPEN_PAYPAL;
        track(name, { [properties.TRIGGER]: pageName });
        setModalState(AddPaymentMethodModalState.ADD_PAYPAL);
        break;
      }
      case LinkPaymentType.GIACT: {
        const { name, properties } = EVENTS.OPEN_GIACT;
        track(name, { [properties.TRIGGER]: pageName });
        setModalState(AddPaymentMethodModalState.ADD_GIACT);
        break;
      }
      case LinkPaymentType.BANKFRICK: {
        handleWireModalOpen(null);
        break;
      }
      case LinkPaymentType.MANUAL: {
        handleWireModalOpen(null);
        break;
      }
      case LinkPaymentType.BANCOLOMBIA: {
        handleOpenBancolombiaModal();
        onClose();
        break;
      }
      case LinkPaymentType.WIRE: {
        handleWireModalOpen(null);
        break;
      }
      case LinkPaymentType.PLAID_OR_GIACT: {
        setModalState(AddPaymentMethodModalState.ADD_BANK_PLAID);
        break;
      }
    }
  };

  const handleClosePlaidModal = () => {
    const domainRegex = /http(?:s?):\/\/.*?([^\.\/]+?\.[^\.]+?)(?:\/|$)/gu;
    const includesDomain = domainRegex.test(redirectUrl);

    if (redirectUrl && !includesDomain) {
      return window.location.replace(decodeURIComponent(redirectUrl));
    }
    setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
    onClose();
  };

  const handlePlaidUnsupportedModalSubmit = () => {
    setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
    onClose();
    if (GiactManualAddBankAccount && defaultFiat === "USD" && countryCode === "us") {
      setModalState(AddPaymentMethodModalState.ADD_GIACT);
    } else {
      handleWireModalOpen(null);
    }
  };

  const handleWireModalOpen = (values?: InitialWireFormType) => {
    setGiactFormValues(values);
    setModalState(AddPaymentMethodModalState.ADD_WIRE);
    toggleWireModal();
  };

  const handleCloseWireModal = () => {
    if (pageName === PAGE_NAME.CASH_DEPOSIT) {
      onSuccess();
    }
    if (refreshPaymentMethodsData) refreshPaymentMethodsData();
    setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
    toggleWireModal();
    onClose();
  };

  const handleCurrencyChange = (currency: CurrencyShortNameFiat) => {
    const { name } = EVENTS.ADD_PAYMENT_METHOD_CURRENCY_CHANGE;
    track(name);
    setSelectedCurrency(currency);
    if (updateSelectedCurrency) updateSelectedCurrency(currency);
    onCurrencyChange && onCurrencyChange(currency);
    setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
  };

  if (modalState === AddPaymentMethodModalState.ADD_BANK_PLAID) {
    return (
      <PlaidModal
        onBack={() => setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD)}
        isPlaidRuxEnabled={bankSettingsProps?.isPlaidRuxEnabled}
        userCountryCode={countryCode}
        isOpen={isOpen}
        // TODO address these duplicate success handlers
        // this success callback closes the modal
        handlePlaidSuccess={() => {
          if (["onboarding"].includes(type)) {
            onSuccess(LinkPaymentType.PLAID);
          } else {
            refreshPaymentMethodsData();
          }
        }}
        // this success callback will not close modal by default
        // custom logic in this modal for onSuccess causes loader to spin after success
        onSuccess={type === "default" && onSuccess ? onSuccess : undefined}
        onClose={handleClosePlaidModal}
        handlePlaidUnsupportedModalSubmit={handlePlaidUnsupportedModalSubmit}
        openGiactModal={() => setModalState(AddPaymentMethodModalState.ADD_GIACT)}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        openSelectPaymentMethodModal={openSelectPaymentMethodModal}
        scope={scope}
        subaccountHashid={subaccountHashid}
        onSuccessCallback={onSuccess}
      />
    );
  }

  if (modalState === AddPaymentMethodModalState.ADD_PAYPAL) {
    return (
      <PayPalModal
        onBack={() => setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD)}
        handlePayPalSuccess={handlePayPalSuccess}
        isOpen={isOpen}
        handleClosePayPal={() => {
          setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
          onClose();
        }}
        paymentMethods={paymentMethods}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        openSelectPaymentMethodModal={openSelectPaymentMethodModal}
        scope={scope}
        subaccountHashid={subaccountHashid}
        onSuccessCallback={onSuccess}
      />
    );
  }
  if (modalState === AddPaymentMethodModalState.ADD_GIACT) {
    return (
      <GiactModal
        onBack={() => setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD)}
        isOpen={isOpen}
        handleFallbackToWire={handleWireModalOpen}
        handleGiactSuccess={handleGiactSuccess}
        handleGiactClose={() => {
          setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
          NewPaymentMethodsEnabled && onClose();
        }}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        openSelectPaymentMethodModal={openSelectPaymentMethodModal}
        scope={scope}
        subaccountHashid={subaccountHashid}
        onSuccessCallback={onSuccess}
      />
    );
  }
  if (modalState === AddPaymentMethodModalState.ADD_WIRE) {
    return (
      <AjaxWireModal
        onBack={
          Boolean(giactFormValues)
            ? () => {
                setModalState(AddPaymentMethodModalState.ADD_GIACT);
                toggleWireModal();
              }
            : () => {
                setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD);
                toggleWireModal();
              }
        }
        setAjaxData={setAjaxData}
        currency={selectedCurrency}
        isOpen={isWireModalOpen}
        modalState={ajaxModalState}
        pageProps={pageProps}
        pageName={ajaxModalPageName}
        closeModal={handleCloseWireModal}
        initialWireFormValues={giactFormValues}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        subaccountHashid={subaccountHashid}
        scope={scope}
      />
    );
  }
  if (modalState === AddPaymentMethodModalState.ADD_DEBIT_CARD) {
    return (
      <AddDebitCardFlowWithIntl
        defaultFiat={selectedCurrency}
        defaultCardHolderName={fullName}
        openAddPaymentMethodsModal={() => setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD)}
        closeAddPaymentMethodsModal={onClose}
        isOpen={isOpen}
        requestRefresh={requestRefresh}
        userDetails={bankSettingsProps?.userDetails}
        openSelectPaymentMethodModal={openSelectPaymentMethodModal}
        scope={scope}
        onSuccessCallback={onSuccess}
        {...addDebitCardFlowProps}
        subaccountHashid={subaccountHashid}
      />
    );
  }

  if (!bankSettingsProps) {
    return (
      <Modal
        title={intl.formatMessage({ defaultMessage: "Add payment method" })}
        loading
        isOpen={isOpen}
        onClose={onClose}
        onBack={onBack}
        hasLargeTitle
      />
    );
  }

  const paymentTypes = listAvailablePaymentTypes(
    account,
    countryCode,
    {
      AddDebitCards,
      BcoloEnabled,
      PayPalEnabled,
      GiactManualAddBankAccount,
      WireTransferEnabled: true,
    },
    bankSettingsProps?.isPlaidSupported,
    selectedCurrency,
    isInstitutional,
    issuingCountryCode,
    isForeignEntityACHDisabled
  );

  const [availablePaymentTypes, ineligiblePaymentTypes] = PaymentTypeBifurcationMap[scope]({
    paymentTypes,
    countryCode,
    scope,
  });

  if (achDisabledFlow) {
    availablePaymentTypes.push(availablePaymentTypes.shift());
  }

  const orderedAvailablePaymentTypes = getOrderedAvailablePaymentTypes(
    isNewAddPaymentsFlowEnabled,
    availablePaymentTypes
  );

  if (modalState === AddPaymentMethodModalState.CHANGE_CURRENCY_MODAL) {
    return (
      <SelectCurrencyModal
        onSubmit={handleCurrencyChange}
        isOpen={isOpen}
        defaultFiat={selectedCurrency}
        onClose={() => setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD)}
      />
    );
  }
  if (modalState === AddPaymentMethodModalState.TRANSFER_LIMIT_AND_TIMINGS_MODAL) {
    return (
      <TransferLimitAndTimingModal
        isOpen={isOpen}
        onBack={() => setModalState(AddPaymentMethodModalState.SELECT_PAYMENT_METHOD)}
        title={intl.formatMessage({ defaultMessage: "Transfer limits & timing" })}
        currency={selectedCurrency}
        subaccountHashid={subaccountHashid}
        isInstitutional={isInstitutional}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      title={
        <Flex align="center" gap="20px">
          {intl.formatMessage({ defaultMessage: "Add payment method" })}
          {shouldShowCurrencyDropdown(
            noPaymentMethodInDeposit,
            scope,
            advancedTradeUIEnabled,
            NewPaymentMethodsEnabled
          ) && (
            <ChangeCurrencyMenu
              currency={selectedCurrency}
              supportedFiat={supportedFiat}
              onChangeCurrencyClick={handleCurrencyChange}
            />
          )}
        </Flex>
      }
      onClose={onClose}
      onBack={onBack}
      testId="add-payment-methods-modal"
      hasLargeTitle
    >
      {achDisabledFlow && (
        <SectionMessage statusType="info" mb={2} data-testid="ach-unavail-message">
          {intl.formatMessage({
            defaultMessage:
              "Bank account linking is temporarily unavailable. We are waiving fees associated with alternate funding methods during this time.",
          })}
        </SectionMessage>
      )}
      <Spacer mt={3}>
        {(!paymentMethods || paymentMethods?.length < 1) && scope === PaymentTypeScope.DEPOSIT && !achDisabledFlow && (
          <SectionMessage statusType="info" mb={2}>
            {intl.formatMessage({
              defaultMessage: "You need to add the payment method first to make the deposit.",
            })}
          </SectionMessage>
        )}
        {!orderedAvailablePaymentTypes.length && NewPaymentMethodsEnabled ? (
          <SectionMessage statusType="info" mb={2}>
            {intl.formatMessage({
              defaultMessage: "You do not have any eligible payment methods to add.",
            })}
          </SectionMessage>
        ) : (
          <Card padding="none" variant="outlined">
            <List>
              {orderedAvailablePaymentTypes.map((paymentType, index) => {
                const shouldRenderUnavailable =
                  achDisabledFlow &&
                  (paymentType === LinkPaymentType.MANUAL ||
                    paymentType === LinkPaymentType.PLAID ||
                    paymentType === LinkPaymentType.GIACT ||
                    paymentType === LinkPaymentType.PLAID_OR_GIACT);
                const isRecommended =
                  !achDisabledFlow &&
                  index === 0 &&
                  orderedAvailablePaymentTypes.length !== 1 &&
                  geminiEntity !== GeminiEntities.GeminiPayments;

                const PaymentMethodOption = renderPaymentMethodByType[paymentType];
                return (
                  PaymentMethodOption && (
                    <PaymentMethodOption
                      key={paymentType}
                      intl={intl}
                      titles={titles}
                      handleClick={handleClick}
                      isRecommended={isRecommended}
                      canManagePaymentMethods={bankSettingsProps?.canManageBankAccounts}
                      isRedirectedFromWithdrawals={isRedirectedFromWithdrawals}
                      templateProps={templateProps}
                      theme={theme}
                      shouldRenderUnavailable={shouldRenderUnavailable}
                      currency={selectedCurrency}
                      countryCode={countryCode}
                      geminiEntity={geminiEntity}
                    />
                  )
                );
              })}
            </List>
          </Card>
        )}
      </Spacer>
      {NewPaymentMethodsEnabled && (
        <Fragment>
          <Spacer mt={3}>
            {Boolean(ineligiblePaymentTypes.length) && (
              <Card padding="none" variant="outlined">
                <Text.Body ml={3} mt={2} bold>
                  {intl.formatMessage({ defaultMessage: "Ineligible" })}
                </Text.Body>
                <Text.Body ml={3} size="xs">
                  {!ineligibleCardSubTitle ? IneligiblePaymentMessageMap(intl)[scope] : ineligibleCardSubTitle}
                </Text.Body>
                <List>
                  {ineligiblePaymentTypes.map(paymentType => {
                    const PaymentMethodOption = renderPaymentMethodByType[paymentType];
                    return (
                      PaymentMethodOption && (
                        <PaymentMethodOption
                          key={paymentType}
                          intl={intl}
                          titles={titles}
                          canManagePaymentMethods={bankSettingsProps?.canManageBankAccounts}
                          showStaking={showStaking}
                          isRedirectedFromWithdrawals={isRedirectedFromWithdrawals}
                          templateProps={templateProps}
                          isDisabled
                          theme={theme}
                          geminiEntity={geminiEntity}
                        />
                      )
                    );
                  })}
                </List>
              </Card>
            )}
          </Spacer>
          {(countryCode === "us" ||
            optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.TRANSFER_LIMITS_TIMINGS_ENABLED)) && (
            <Spacer mt={3}>
              <Flex justify="center" alignItems="center">
                <Button.Tertiary
                  size="lg"
                  data-testid="transfer-limit-and-timing-button"
                  aria-label={intl.formatMessage({ defaultMessage: "Open transfer limit and timing modal" })}
                  rightElement={<IconInfoOutlined />}
                  onClick={() => {
                    const { name } = EVENTS.OPEN_TRANSFER_TIMING_AND_LIMIT_MODAL;
                    track(name);
                    setModalState(AddPaymentMethodModalState.TRANSFER_LIMIT_AND_TIMINGS_MODAL);
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Transfer limits & timing" })}
                </Button.Tertiary>
              </Flex>
            </Spacer>
          )}
        </Fragment>
      )}
    </Modal>
  );
};

const useAddPaymentMethodModal = () => {
  const [modalState, setModalState] = React.useState<AddPaymentMethodModalState>(() => {
    if (isPlaidRedirect()) {
      return AddPaymentMethodModalState.ADD_BANK_PLAID;
    }
    return AddPaymentMethodModalState.NONE;
  });

  const setShowModal = React.useCallback((shouldShow: boolean) => {
    setModalState(shouldShow ? AddPaymentMethodModalState.SELECT_PAYMENT_METHOD : AddPaymentMethodModalState.NONE);
  }, []);

  return {
    modalState,
    setShowModal,
    isOpen: modalState !== AddPaymentMethodModalState.NONE,
  };
};

export const AddPaymentMethodsModalAndTrigger = ({
  handleOpenBancolombiaModal,
  type,
  updateSelectedCurrency,
  addDebitCardFlowProps,
  combinedPaymentMethods,
}: Omit<AddPaymentMethodOptionsProps, "handleOpenWireModal"> & {}) => {
  const {
    pageProps: { paymentMethods },
    templateProps: {
      user: { subaccountHashid },
    },
  } = usePageData<BankSettingsPageDataType>();
  const { requestRefresh } = usePageRefresh();
  const { isOpen, modalState, setShowModal } = useAddPaymentMethodModal();
  const { lockout } = useLockout();
  const { toggleModal } = useGlobalModal();
  const handleClick = () => {
    if (getIsUserInOnboarding(lockout)) {
      toggleModal<VerificationPendingProps>(GlobalModalType.VerificationPending, {
        actionType: VerificationPendingActionType.AddPayment,
      });
    } else {
      setShowModal(true);
    }
  };
  const { intl } = useIntl();
  return (
    <React.Fragment>
      <AddPaymentMethodsModal
        isOpen={isOpen}
        onClose={() => setShowModal(false)}
        handleOpenBancolombiaModal={handleOpenBancolombiaModal}
        initialModalState={modalState}
        paymentMethods={paymentMethods}
        refreshPaymentMethodsData={() => requestRefresh()}
        type={type}
        scope={PaymentTypeScope.ALL}
        updateSelectedCurrency={updateSelectedCurrency}
        addDebitCardFlowProps={addDebitCardFlowProps}
        combinedPaymentMethods={combinedPaymentMethods}
        subaccountHashid={subaccountHashid}
      />
      <Button.Secondary data-testid="open-add-payment-modal-trigger" onClick={handleClick}>
        {intl.formatMessage({ defaultMessage: "Add payment method" })}
      </Button.Secondary>
    </React.Fragment>
  );
};
