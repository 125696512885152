import DepositInstructionChangeHeader from "@gemini-ui/components/Header/DepositInstructionChangeHeader";
import UkGeneralRiskWarning from "@gemini-ui/components/Header/UkGeneralRiskWarning";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { User } from "@gemini-ui/constants/templateProps/users";
import { usePageData } from "@gemini-ui/contexts";
import { getSGUser } from "@gemini-ui/pages/settings/BankSettings/utils/getSGUser";
import { getUKUser } from "@gemini-ui/pages/settings/BankSettings/utils/getUKUser";

interface Builder {
  countryCondition: (countryCode: User["countryCode"], geminiEntity: GeminiEntity) => boolean;
  Component: React.FC;
}

class SGBanner implements Builder {
  countryCondition = getSGUser;
  Component = DepositInstructionChangeHeader;
}

class UKBanner implements Builder {
  countryCondition = getUKUser;
  Component = UkGeneralRiskWarning;
}

const builders: Builder[] = [new SGBanner(), new UKBanner()];

const useBannerBuilders = () => {
  const {
    templateProps: {
      user: { countryCode },
      account: { geminiEntity },
    },
  } = usePageData();

  return builders.find(banner => banner.countryCondition(countryCode, geminiEntity));
};

export const AnnouncementBanner = () => {
  const Builder = useBannerBuilders();
  if (!Builder) return null;

  return <Builder.Component />;
};
